@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    display: flex;
    flex-direction: column;
  }

  .trigger {
    visibility: hidden;
  }
  
  .spinner-wrap {
    width: 100%;
    padding: 30px;
  }
}
