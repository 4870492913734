@use '../../styles/size.scss' as v;

.content {
  height: calc(100vh - #{v.$header-height} - 179px);
}

.block {
  max-width: 630px;
  margin-top: 154px;
}

.text {
  font-size: 14px;
  color: var(--white2);
  line-height: 160%;
  margin-bottom: 30px;
}

.spinnerWrap {
  width: 100%;
  padding: 30px;
}

.spinnerText {
  margin-left: 12px;
}